import { Pipe, PipeTransform } from '@angular/core';
import { isObservable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Pipe({
  name: 'loading',
  standalone: true,
})
export class Loading implements PipeTransform {
  transform(val) {
    return isObservable(val)
      ? val.pipe(
          map(() => false),
          startWith(true)
        )
      : val;
  }
}
